import React from "react";
import { SideBar } from "../../layouts/sidebar";
import { TopMenu } from "../../layouts/top_menu";
import {
  updateCost,
  getCost,
  getAllCosts,
  createCost,
} from "../../../models/costs";
import { Modal, Spinner } from "react-bootstrap";
import swal from "sweetalert";

export class Costs extends React.Component {
  constructor() {
    super();
    this.state = {
      costs: [],
      add: {},
      input: {},
      is_loading: false,
      is_cost_loading: false,
      selectedID: 1,
      edit_popup: false,
      add_popup: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleChangeAdd = this.handleChangeAdd.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.getCosts();
  }

  handleChange(event) {
    let { input } = this.state;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  handleChangeAdd(event) {
    let { add } = this.state;
    add[event.target.name] = event.target.value;
    this.setState({ add });
  }

  handleUpdate = async (event) => {
    event.preventDefault();
    try {
      let payload = this.state.input;
      const response = await updateCost(payload, this.state.selectedID);
      if (response && response.data && response.data.id) {
        this.getCosts();
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        }).catch((response) => {
          swal("Failed", response.message, "error");
        });
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let payload = this.state.add;
      const response = await createCost(payload);
      if (response && response.data && response.data.id) {
        this.getCosts();
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        }).catch((response) => {
          swal("Failed", response.message, "error");
        });
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  async getCosts() {
    this.setState({ is_loading: true });
    let response = await getAllCosts();
    if (response.success) {
      this.setState({ costs: response.data, is_loading: false });
    }
  }

  async getInitCost(id) {
    this.setState({ is_cost_loading: true, selectedID: id });
    let response = await getCost(id);
    if (response.success) {
      this.setState({ input: response.data, is_cost_loading: false });
    }
  }

  render() {
    let { costs } = this.state;
    return (
      <React.Fragment>
        <div className="hold-transition sidebar-mini">
          <div className="wrapper">
            <TopMenu title="Cost Settings" />
            <SideBar />
            <div className="content-wrapper">
              <section className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-12">
                      <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">
                          <button
                            className="btn btn-outline-primary"
                            type="button"
                            onClick={() => {
                              this.setState({
                                add_popup: !this.state.isOadd_popuppen,
                              });
                            }}
                          >
                            Add New Cost
                          </button>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>

              <section>
                {this.state.is_loading && (
                  <div className="text-center p-5">
                    <Spinner animation="grow" size="lg" />
                  </div>
                )}

                <section className="content">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>Copy Rate</th>
                                <th>Scheme Rate</th>
                                <th>Free Rate</th>
                                <th>Last Updated</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {costs.length > 0 &&
                                costs.map((item, i) => {
                                  return (
                                    <tr>
                                      <td>{item.id}</td>
                                      <td>{item.copy_rate}</td>
                                      <td>{item.scheme_rate}</td>
                                      <td>{item.free_rate}</td>
                                      <td>{item.updated_at}</td>
                                      <td>
                                        {item.active === "1" ? (
                                          <span class="text-success">
                                            Active
                                          </span>
                                        ) : (
                                          <span class="text-danger">
                                            Disabled
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <button
                                          className="btn btn-outline-primary btn-sm"
                                          onClick={() => {
                                            this.setState({
                                              edit_popup: true,
                                            });
                                            this.getInitCost(item.id);
                                          }}
                                        >
                                          Edit
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            </div>
          </div>
        </div>

        <Modal
          size="lg"
          show={this.state.edit_popup}
          onHide={() => {
            this.setState({ edit_popup: false });
          }}
          animation={true}
          aria-labelledby="example-modal-sizes-title-lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="edit-scheme">Update Cost</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!this.state.is_cost_loading && (
              <form autoComplete="off" onSubmit={this.handleUpdate}>
                <section className="content">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card card-secondary">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-sm-3">
                                <div className="form-group">
                                  <label for="inputReceipt">Copy Rate</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Copy rate"
                                    name="copy_rate"
                                    value={this.state.input.copy_rate}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-3">
                                <div className="form-group">
                                  <label for="inputReceipt">Scheme Rate</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Scheme rate"
                                    name="scheme_rate"
                                    value={this.state.input.scheme_rate}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-3">
                                <div className="form-group">
                                  <label for="inputReceipt">
                                    Free Copy Rate
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Free Copy rate"
                                    name="free_rate"
                                    value={this.state.input.free_rate}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-3">
                                <div className="form-group">
                                  <label for="inputReceipt">Status </label>
                                  <select
                                    className="form-control"
                                    name="active"
                                    onChange={this.handleChange}
                                    value={this.state.input.active}
                                  >
                                    <option value={0}>Disabled</option>
                                    <option value={1}>Active</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-sm-3">
                                <label for="inputReceipt">&nbsp;</label>
                                <button
                                  type="submit"
                                  className="form-control btn btn-primary"
                                >
                                  <i className="fa fa-save" />
                                  &nbsp; Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </form>
            )}
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={this.state.add_popup}
          onHide={() => {
            this.setState({ add_popup: false });
          }}
          animation={true}
          aria-labelledby="example-modal-sizes-title-lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="edit-scheme">Add New Cost</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!this.state.is_cost_loading && (
              <form autoComplete="off" onSubmit={this.handleSubmit}>
                <section className="content">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card card-secondary">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-sm-3">
                                <div className="form-group">
                                  <label for="inputReceipt">Copy Rate</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Copy rate"
                                    name="copy_rate"
                                    value={this.state.add.copy_rate}
                                    onChange={this.handleChangeAdd}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-3">
                                <div className="form-group">
                                  <label for="inputReceipt">Scheme Rate</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Scheme rate"
                                    name="scheme_rate"
                                    value={this.state.add.scheme_rate}
                                    onChange={this.handleChangeAdd}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-3">
                                <div className="form-group">
                                  <label for="inputReceipt">
                                    Free Copy Rate
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Free Copy rate"
                                    name="free_rate"
                                    value={this.state.add.free_rate}
                                    onChange={this.handleChangeAdd}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-3">
                                <div className="form-group">
                                  <label for="inputReceipt">Status </label>
                                  <select
                                    className="form-control"
                                    name="active"
                                    onChange={this.handleChangeAdd}
                                    value={this.state.add.active}
                                  >
                                    <option value={0}>Disabled</option>
                                    <option value={1}>Active</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-sm-3">
                                <label for="inputReceipt">&nbsp;</label>
                                <button
                                  type="submit"
                                  className="form-control btn btn-primary"
                                >
                                  <i className="fa fa-save" />
                                  &nbsp; Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </form>
            )}
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
