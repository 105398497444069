import React from "react";
import { SideBar } from "../../layouts/sidebar";
import { TopMenu } from "../../layouts/top_menu";
import { getAllDistricts } from "../../../models/districts";
import { Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { getAllAdvertisements } from "../../../models/advertisements";
import DataTable from "react-data-table-component";
import { tableCustomStyles } from "../../../constants/styles";
import { Link } from "react-router-dom";
import { getBranches } from "../../../models/branches";
import { getAllAdAgencys } from "../../../models/ad_agencies";
import { apiUrl } from "../../../constants/global";
import { getLoggedUser } from "../../../state/user";
const conditionalRowStyles = [
  {
    when: (row) => row.is_opened === 0,
    style: {
      backgroundColor: "#ffc3c3",
    },
  },
];
const statuses = {
  N: {
    value: "Not paid",
    color: "danger",
  },
  P: {
    value: "Paid",
    color: "success",
  },
};
const user = getLoggedUser();
export class ReportAds extends React.Component {
  constructor() {
    super();
    this.state = {
      qstring: "",
      selectedID: null,
      selectedRow: {},
      due_amount: 0,
      popup: false,
      is_hide: false,
      isOpen: false,
      ads: [],
      districts: [],
      totalRows: 0,
      currentPage: 1,
      page_input: {
        page: 1,
        limit: 15,
      },
      input: {},
      update: {},
      pwd: {},
      query: "",
      is_loading: false,
      data_arr: {},
      is_saving: false,
      incr: 0,
      payment_popup: false,
      payment: {},
      edit_popup: false,
      branches: [],
      agencies: [],
      gst: {},
      columns: [
        {
          name: "ID",
          sortable: true,
          wrap: true,
          width: "100px",
          cell: (row) => row.id,
        },
        {
          name: "Client/Agent",
          cell: (row) => (row.agency_info ? row.agency_info.name : ""),
        },
        {
          name: "Category",
          cell: (row) => {
            if (user.role === "A" && row.category === "DAVP") {
              return "Magazine";
            }
            return row.category;
          },
        },
        {
          name: "Bill Number",
          wrap: true,
          cell: (row) => row.bill_number,
        },
        {
          name: "Bill Date",
          wrap: true,
          cell: (row) => row.bill_date,
        },
        {
          name: "Taxable Amount",
          wrap: true,
          cell: (row) => row.taxable_amount,
        },
        {
          name: "Net Amount",
          wrap: true,
          cell: (row) => row.net_amount,
        },
        {
          name: "Paid",
          wrap: true,
          cell: (row) => row.paid_amount,
        },
        {
          name: "Reference number",
          wrap: true,
          cell: (row) => row.reference_number,
        },
        {
          name: "Due",
          wrap: true,
          cell: (row) => row.due_amount,
        },
        {
          name: "Payment Mode",
          wrap: true,
          cell: (row) => row.payment_mode,
        },
        {
          name: "Date of payment",
          wrap: true,
          cell: (row) => row.payment_date,
        },
        {
          name: "Status",
          cell: (row) => (
            <span className={`text-${statuses[row.status].color}`}>
              {statuses[row.status].value}
            </span>
          ),
        },
      ],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ is_hide: true });
    }, 500);
    this.loadDistricts();
    let input = this.state.page_input;
    this.loadads(input);
    this.loadBranches();
    this.loadDistricts();

    // const currentYear = new Date().getFullYear();
    // const currentMonth = new Date().getMonth() + 1;
    // input.month = currentMonth;
    // input.year = currentYear;
    // this.setState({ input });
  }

  async loadBranches() {
    let q = "all=1";
    let response = await getBranches(q);
    if (response.success) {
      this.setState({ branches: response.data });
    }
  }

  async loadDistricts() {
    let q = "all=1";
    let response = await getAllDistricts(q);
    if (response.success) {
      this.setState({ districts: response.data });
    }
  }

  async loadAgencies(dist) {
    this.setState({ is_loading: true });
    this.setState({ agencies: {} });
    let q = new URLSearchParams({
      district: dist,
    }).toString();
    let response = await getAllAdAgencys(q);
    if (response.success) {
      this.setState({ is_loading: false });
      this.setState({ agencies: response.data });
    } else {
      this.setState({ agencies: [] });
      this.setState({ is_loading: false });
      swal("Failed", "No data found !!!", "error");
    }
  }

  handleChange(event) {
    let { input } = this.state;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "district") {
      let { page_input } = this.state;
      page_input.ad_agency = "";
      page_input.district = event.target.value;
      this.loadAgencies(event.target.value);
      this.loadads(page_input);
    }
    if (event.target.name === "ad_agency") {
      let { page_input } = this.state;
      page_input.ad_agency = event.target.value;
      this.loadads(page_input);
    }
    if (event.target.name === "status") {
      let { page_input } = this.state;
      page_input.status = event.target.value;
      this.loadads(page_input);
    }
    if (event.target.name === "category") {
      let { page_input } = this.state;
      page_input.category = event.target.value;
      this.loadads(page_input);
    }

    if (event.target.name === "year" && event.target.value && input.month) {
      let { page_input } = this.state;
      page_input.year = event.target.value;
      page_input.month = input.month;
      this.loadads(page_input);
    }
    if (event.target.name === "month" && event.target.value && input.year) {
      let { page_input } = this.state;
      page_input.month = event.target.value;
      page_input.year = input.year;
      this.loadads(page_input);
    }
  }

  handlePageChange = (page) => {
    let pinput = this.state.page_input;
    pinput.page = page;
    this.setState({ currentPage: page });
    this.setState({ page_input: pinput });
    this.loadAll(pinput);
  };

  async loadads(input) {
    console.log(input);
    let { data_arr } = this.state;
    this.setState({ is_loading: true });
    data_arr = {};
    this.setState({ data_arr });
    this.setState({ ads: {} });

    if (
      this.state.input.district &&
      typeof this.state.input.district !== "undefined"
    ) {
      input.district = this.state.input.district;
    }
    let q = new URLSearchParams(input).toString();
    let response = await getAllAdvertisements(q);
    if (response.success) {
      this.setState({ is_loading: false });
      this.setState({ ads: response.data });
    } else {
      this.setState({ ads: [] });
      this.setState({ is_loading: false });
      swal("Failed", "No data found !!!", "error");
    }
  }

  render() {
    let { input } = this.state;
    if (user.role !== "A") {
      input.branch = user.branch;
    }
    let qp = new URLSearchParams(input).toString();

    let months = [
      { id: 1, name: "January" },
      { id: 2, name: "February" },
      { id: 3, name: "March" },
      { id: 4, name: "April" },
      { id: 5, name: "May" },
      { id: 6, name: "June" },
      { id: 7, name: "July" },
      { id: 8, name: "August" },
      { id: 9, name: "September" },
      { id: 10, name: "October" },
      { id: 11, name: "November" },
      { id: 12, name: "December" },
    ];
    const currentYear = new Date().getFullYear();
    const startYear = 2000;
    let years = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }
    return (
      <React.Fragment>
        <div className="hold-transition sidebar-mini">
          <div className="wrapper">
            <TopMenu title="Advertisement List" />
            <SideBar />
            <div className="content-wrapper p-2">
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-secondary">
                        <div className="card-header d-flex">
                          <h3 className="card-title mr-auto">Filter</h3>
                          <Link
                            to="/create_ads"
                            className="btn btn-outline-warning btn-sm"
                          >
                            Add New Agency
                          </Link>
                        </div>

                        <div className="card-body">
                          <div className="row">
                            {/* <div className="col-sm-2">
                              <div className="form-group">
                                <label for="inputDate">Bill Date</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="Enter Date"
                                  name="bill_date"
                                  value={this.state.input.bill_date}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div> */}
                            <div className="col-sm-2">
                              <div className="form-group">
                                <label for="inputDate">Month</label>
                                <select
                                  className="form-control"
                                  name="month"
                                  value={this.state.input.month}
                                  onChange={this.handleChange}
                                >
                                  <option value="">Month</option>
                                  {months.length > 0 &&
                                    months.map((item, i) => {
                                      return (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-1">
                              <div className="form-group">
                                <label for="inputDate">Year</label>
                                <select
                                  className="form-control"
                                  name="year"
                                  value={this.state.input.year}
                                  onChange={this.handleChange}
                                >
                                  <option value="">Year</option>
                                  {years.length > 0 &&
                                    years.map((item) => {
                                      return (
                                        <option value={item}>{item}</option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-2">
                              <div className="form-group">
                                <label for="inputDate">Category</label>
                                <select
                                  className="form-control"
                                  name="category"
                                  value={this.state.input.category}
                                  onChange={this.handleChange}
                                >
                                  <option value="">--Select--</option>
                                  <option value="Private">Private</option>
                                  <option value="PRD">PRD</option>
                                  <option value="Lottery">Lottery</option>
                                  <option value="DAVP">
                                    {user.role === "A" ? "Magazine" : "DAVP"}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-2">
                              <div className="form-group">
                                <label for="inputDate">District</label>
                                <select
                                  className="form-control"
                                  name="district"
                                  value={this.state.input.district}
                                  onChange={this.handleChange}
                                >
                                  <option value="">--Select--</option>
                                  {this.state.districts.length > 0 &&
                                    this.state.districts.map((item, i) => {
                                      return (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-2">
                              <div className="form-group">
                                <label for="inputDate">Agency</label>
                                <select
                                  className="form-control"
                                  name="ad_agency"
                                  value={this.state.input.ad_agency}
                                  onChange={this.handleChange}
                                >
                                  <option value="">--Select--</option>
                                  {this.state.agencies.length > 0 &&
                                    this.state.agencies.map((item, i) => {
                                      return (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-2">
                              <div className="form-group">
                                <label for="inputDate">Status</label>
                                <select
                                  className="form-control"
                                  name="status"
                                  value={this.state.input.status}
                                  onChange={this.handleChange}
                                >
                                  <option value="">--Select--</option>
                                  <option value="P">Paid</option>
                                  <option value="N">Not Paid</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-secondary">
                        <div className="card-header d-flex">
                          <h3 className="card-title m-auto">
                            List of ads ({this.state.ads.length})
                          </h3>
                          <a
                            href={apiUrl + "ad_excel?" + qp}
                            target="_new"
                            className="btn btn-success"
                          >
                            <i className="fa fa-excel" />
                            <span>Download Excel</span>
                          </a>
                        </div>

                        <div
                          className="card-body table-responsive p-0"
                          style={{ height: "450px" }}
                        >
                          <div className="row">
                            <div className="col-md-12">
                              <div>
                                {this.state.is_loading && (
                                  <div className="text-center p-5">
                                    <Spinner animation="grow" size="lg" />
                                  </div>
                                )}

                                {!this.state.is_loading &&
                                  this.state.ads.length > 0 && (
                                    <DataTable
                                      customStyles={tableCustomStyles}
                                      title=""
                                      fixedHeader
                                      columns={this.state.columns}
                                      data={this.state.ads}
                                      highlightOnHover
                                      pagination
                                      paginationServer
                                      paginationTotalRows={this.state.totalRows}
                                      paginationPerPage={15}
                                      paginationDefaultPage={
                                        this.state.currentPage
                                      }
                                      paginationRowsPerPageOptions={[
                                        15, 25, 50, 100,
                                      ]}
                                      paginationComponentOptions={{
                                        rowsPerPageText: "Ads per page:",
                                        rangeSeparatorText: "out of",
                                      }}
                                      onChangePage={this.handlePageChange}
                                      conditionalRowStyles={
                                        conditionalRowStyles
                                      }
                                    />
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
